<script lang="ts" setup xmlns="http://www.w3.org/1999/html">
import type {Area} from "#magnolia-layer/types/magnolia-types";
import type {CardComponent} from "~/types/cardComponent";
import {EditableArea} from "@magnolia/vue-editor";
import {useMediaQuery} from "@vueuse/core";
import Slider from "~/components/Slider.vue";
import Card from "~/templates/components/Card.vue";

const props = defineProps<{
  cards?: Area;
}>();

const slides = computed(() => {
  if (!props.cards || !props.cards["@nodes"]) return;
  let slides: CardComponent[] = [];
  props.cards["@nodes"].forEach((index) => {
    slides.push(props.cards![index]);
  });

  return slides;
});

const mobile: Ref<boolean> = useMediaQuery("(max-width: 1023px)");
</script>

<template>
  <section class="inner-content-grid my-12 relative">
    <Slider v-if="mobile">
      <template v-slot:slides>
        <swiper-slide v-for="(slide, i) in slides" :key="i">
          <Card
            :title="slide.title"
            :image="slide.image"
            :body="slide.body"
            :button="slide.button">
          </Card>
        </swiper-slide>
      </template>
    </Slider>
    <div v-else>
      <EditableArea :content="cards" class="inner-content-grid" />
    </div>
  </section>
</template>

<style lang="scss" scoped>
:deep(button) {
  @apply top-[unset] top-[120px] -translate-x-[10%] text-pumpkin;
}

:deep(button#next) {
  @apply translate-x-[10%];
}

:deep(.trimm-button) {
  @apply mb-16 lg:mb-0;
}
</style>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
<script lang="ts" setup>
import TextBlock from "~/components/TextBlock.vue";
import type {Button} from "~/types/button";
import {EditableArea} from "@magnolia/vue-editor";
import type {Area} from "#magnolia-layer/types/magnolia-types";

const props = withDefaults(
  defineProps<{
    eyebrow?: string;
    title?: string;
    body?: string;
    actions?: Area;
    small?: boolean;
  }>(),
  {
    small: true,
  },
);
</script>

<template>
  <section class="inner-content-grid my-12">
    <div :class="{'col-span-4 lg:col-span-6': small}">
      <TextBlock :eyebrow="eyebrow" :title="title" :body="body">
        <template #actions>
          <EditableArea :content="actions" class="flex flex-wrap gap-4" />
        </template>
      </TextBlock>
    </div>
  </section>
</template>

<style lang="scss" scoped></style>

<script setup lang="ts">
import PlayerAwaitingLoad from "~/components/video/PlayerAwaitingLoad.vue";
import isVNodeEmtpy from "~/utils/isVNodeEmtpy";

const {id} = defineProps<{
  id: number;
}>();

const slots = useSlots();

const hasDefaultSlotWithContent = computed(() => {
  const slotContent = slots.default ? slots.default() : [];
  return !isVNodeEmtpy(slotContent);
});
</script>

<template>
  <ScriptVimeoPlayer
    class="rounded-[1rem] overflow-hidden relative"
    :id="id"
    :vimeo-options="{width: 1920}">
    <template v-if="hasDefaultSlotWithContent" #placeholder>
      <slot></slot>
    </template>
    <template #awaitingLoad>
      <PlayerAwaitingLoad />
    </template>
  </ScriptVimeoPlayer>
</template>

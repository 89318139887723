<script lang="ts" setup>
import type {Area, Page} from "#magnolia-layer/types/magnolia-types";
import {EditableArea} from "@magnolia/vue-editor";

defineOptions({
  inheritAttrs: false,
});

const props = defineProps<{
  title?: string;
  metadata: Page;
  header?: Area;
  main: Area;
  footer: Area;
}>();

const readingCls = computed(() => {
  const {metadata} = props;
  const isReading = metadata["mgnl:template"].includes("pages/reading");
  return isReading
    ? "reading relative after:bg-black/40 after:backdrop-blur-sm after:absolute after:z-[-1] after:inset-0 pt-36 -mt-36"
    : "";
});
</script>

<template>
  <EditableArea
    v-if="header"
    :content="header"
    class="content-grid mt-[64px]"
    elementType="header"></EditableArea>
  <EditableArea
    v-if="main"
    :content="main"
    class="content-grid"
    :class="readingCls"
    elementType="main"></EditableArea>
  <EditableArea
    v-if="footer"
    :content="footer"
    class="content-grid mt-auto"
    elementType="footer"></EditableArea>
</template>
<style lang="scss" scoped>
main.reading::after {
  mask: linear-gradient(to bottom, transparent 0%, black 6%);
}
</style>

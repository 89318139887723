<script lang="ts" setup>
// Yes this is a large file, yes this can be improved (will do later ;))

import {
  DependentFieldDependentField,
  FieldGroup,
  FormDefinitionBase,
} from "@hubspot/api-client/lib/codegen/marketing/forms";
import {SvgoRightArrow, UCheckbox, UInput} from "#components";
import {z, ZodType} from "zod";
import type {FormSubmitEvent} from "#ui/types";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import {format} from "date-fns";

const {executeRecaptcha} = useRecaptcha();
const {t} = useI18n();
const {dataLayer} = useScriptGoogleTagManager();

const props = defineProps<{
  portalId: string;
  formId: string;
}>();

let success: Ref<boolean | undefined> = ref(undefined);
const top = useTemplateRef("top");

if (import.meta.client) {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
}

const hasOnlyHiddenFields = (group: FieldGroup) => {
  if (!group.fields) return;
  const find: DependentFieldDependentField | undefined = group.fields.find(
    (f) => !f.hidden,
  );
  return find === undefined;
};

const formDefinitionBase: FormDefinitionBase = await $fetch(
  "/api/hubspot/forms",
  {
    query: {
      formId: props.formId,
    },
  },
);
const groups: Array<FieldGroup> = formDefinitionBase.fieldGroups;

let state: {[key: string]: any} = reactive({
  communications: {},
});

const schemaObject: {[key: string]: any} = {
  "g-recaptcha-response": z.string().optional(),
  communications: z
    .object(
      formDefinitionBase.legalConsentOptions.communicationsCheckboxes.reduce(
        (acc, cb) => {
          const name = cb.subscriptionTypeId;
          if (cb.required) {
            acc[name] = z.literal(true, {
              message: t("form.validation.required"),
              errorMap: (err, ctx) => {
                return {
                  message:
                    err.code === "invalid_literal"
                      ? t("form.validation.required")
                      : ctx.defaultError,
                };
              },
            });
            return acc;
          }
          acc[name] = z.boolean();
          return acc;
        },
        {} as Record<string, ZodType>,
      ),
    )
    .required(),
};

groups.forEach((group) => {
  if (!group.fields) return;
  group.fields.forEach((field) => {
    if (field.defaultValue) {
      state[field.name] = field.defaultValue;
    }

    switch (String(field.fieldType)) {
      case "single_line_text":
      case "multi_line_text":
      case "email":
      case "dropdown":
        if (field.defaultValues) {
          state[field.name] = field.defaultValues[0];
        }

        let zod = z.string({message: t("form.validation.required")});

        if (String(field.fieldType) === "email")
          zod = zod.email({message: t("form.validation.invalidEmail")});

        if (!field.required) {
          schemaObject[field.name] = zod.optional();
        } else {
          schemaObject[field.name] = zod;
        }
        break;
      case "phone":
        let zodPhone = z.string({
          message: t("form.validation.required"),
        });

        if (field.validation) {
          if (field.validation.minAllowedDigits) {
            zodPhone = zodPhone.min(
              field.validation.minAllowedDigits,
              t("form.validation.min", [field.validation.minAllowedDigits]),
            );
          }
          if (field.validation.maxAllowedDigits) {
            zodPhone = zodPhone.max(
              field.validation.maxAllowedDigits,
              t("form.validation.max", [field.validation.maxAllowedDigits]),
            );
          }
        }

        if (!field.required) {
          schemaObject[field.name] = zodPhone.optional();
        } else {
          schemaObject[field.name] = zodPhone;
        }
        break;
      case "single_checkbox":
        if (!field.required) {
          schemaObject[field.name] = z.boolean();
        } else {
          schemaObject[field.name] = z.literal(true, {
            message: t("form.validation.required"),
            errorMap: (err, ctx) => {
              return {
                message:
                  err.code === "invalid_literal"
                    ? t("form.validation.required")
                    : ctx.defaultError,
              };
            },
          });
        }
        break;
      case "number":
        let zodNumber = z.number({message: t("form.validation.required")});

        if (field.validation) {
          if (field.validation.minAllowedDigits) {
            zodNumber = zodNumber.min(
              field.validation.minAllowedDigits,
              t("form.validation.lower", [field.validation.minAllowedDigits]),
            );
          }
          if (field.validation.maxAllowedDigits) {
            zodNumber = zodNumber.max(
              field.validation.maxAllowedDigits,
              t("form.validation.greater", [field.validation.maxAllowedDigits]),
            );
          }
        }

        if (!field.required) {
          schemaObject[field.name] = zodNumber.optional();
        } else {
          schemaObject[field.name] = zodNumber;
        }
        break;
      case "multiple_checkboxes":
        state[field.name] = [];

        if (field.defaultValues) {
          state[field.name] = field.defaultValues;
        }

        let zodArray = z
          .string({message: t("form.validation.required")})
          .array();

        if (!field.required) {
          schemaObject[field.name] = zodArray.optional();
        } else {
          schemaObject[field.name] = zodArray.min(1, {
            message: t("form.validation.required"),
          });
        }
        break;
      case "datepicker":
        let zodDate = z.date({message: t("form.validation.required")});

        if (!field.required) {
          schemaObject[field.name] = zodDate.optional();
        } else {
          schemaObject[field.name] = zodDate;
        }
        break;
      default:
        if (!field.required) {
          schemaObject[field.name] = z.any().optional();
        } else {
          schemaObject[field.name] = z.any();
        }
    }
  });
});

const schema = z.object(schemaObject);
type Schema = z.output<typeof schema>;

async function onSubmit(event: FormSubmitEvent<Schema>) {
  const body: {[key: string]: any} = {
    form: {
      fields: [],
      context: {
        pageUri: window.location.href,
        pageName: document.title ?? "",
      },
      legalConsentOptions: {
        consent: {},
      },
    },
  };

  const token = await executeRecaptcha("submit");
  if (token) {
    body["g-recaptcha-response"] = token;
  }

  // Static true because of text with dynamic form is `By clicking submit below, you consent to allow TRIMM to store and process the personal information submitted above to provide you the content requested.`
  body.form.legalConsentOptions.consent = {
    consentToProcess: true,
    text: formDefinitionBase.legalConsentOptions.consentToProcessText,
    communications: [],
  };

  for (let key in event.data.communications) {
    const field =
      formDefinitionBase.legalConsentOptions.communicationsCheckboxes.find(
        (cb) => {
          return cb.subscriptionTypeId.toString() === key;
        },
      );

    if (!field) return;

    body.form.legalConsentOptions.consent.communications.push({
      value: event.data.communications[key],
      subscriptionTypeId: field.subscriptionTypeId,
      text: field.label,
    });
  }

  delete event.data.communications; // To prevent looping over it in the next part

  for (let dataKey in event.data) {
    groups.forEach((group) => {
      if (!group.fields) return;
      const field: DependentFieldDependentField | undefined = group.fields.find(
        (field) => field.name === dataKey,
      );
      if (!field) return;

      if (field.fieldType == "multiple_checkboxes") {
        body.form.fields.push({
          objectTypeId: field.objectTypeId,
          name: dataKey,
          value: event.data[dataKey].join(";"),
        });
      } else if (field.fieldType == "datepicker") {
        body.form.fields.push({
          objectTypeId: field.objectTypeId,
          name: dataKey,
          value: format(event.data[dataKey], "yyyy-MM-dd"),
        });
      } else {
        body.form.fields.push({
          objectTypeId: field.objectTypeId,
          name: dataKey,
          value: event.data[dataKey],
        });
      }
    });
  }

  try {
    const response = await $fetch("/api/hubspot/forms", {
      query: {
        portalId: props.portalId,
        formId: props.formId,
      },
      method: "POST",
      body: body,
    });

    if (response) {
      success.value = true;
      dataLayer.push({
        event: "form_submit",
        formName: "hubspot",
        submission: "success",
      });
    }
  } catch (e) {
    success.value = false;
  } finally {
    for (const prop in state) {
      delete state[prop];
    }

    if (top.value) {
      const y = top.value.offsetTop;

      gsap.to(window, {
        duration: 1,
        scrollTo: {
          y,
        },
        ease: "Power1.easeInOut",
      });
    }
  }
}
</script>

<template>
  <div class="inner-content-grid" ref="top">
    <UAlert
      v-if="success"
      icon="i-heroicons-check-circle-solid"
      color="patina"
      variant="solid"
      :title="$t('form.submitted.title')"
      :description="formDefinitionBase.configuration.postSubmitAction.value">
      <template #description>
        <div
          v-html="
            formDefinitionBase.configuration.postSubmitAction.value
          "></div>
      </template>
    </UAlert>

    <UAlert
      v-else-if="success === false"
      icon="i-heroicons-x-circle-solid"
      color="scarlet"
      variant="solid"
      :title="$t('form.failed.title')"
      :description="$t('form.failed.description')" />
  </div>

  <UForm
    v-if="success === undefined"
    :schema="schema"
    :state="state"
    @submit.prevent="onSubmit"
    class="flex flex-col gap-10">
    <div
      v-for="group in groups"
      class="flex gap-10 flex-col lg:flex-row"
      :class="{'-mb-10': hasOnlyHiddenFields(group)}">
      <template v-if="group.fields" v-for="field in group.fields">
        <UFormGroup
          v-if="field.fieldType === 'single_checkbox'"
          class="flex-1"
          :class="{hidden: field.hidden}"
          :required="field.required"
          :description="field.description"
          :name="field.name">
          <UCheckbox
            class="w-fit"
            :label="field.label"
            :required="field.required"
            :placeholder="field.placeholder"
            v-model="state[field.name]">
          </UCheckbox>
        </UFormGroup>

        <UFormGroup
          v-else-if="field.fieldType === 'multi_line_text'"
          class="flex-1"
          :class="{hidden: field.hidden}"
          :required="field.required"
          :label="field.label"
          :description="field.description"
          :name="field.name">
          <UTextarea
            :rows="10"
            :placeholder="field.placeholder"
            v-model="state[field.name]"></UTextarea>
        </UFormGroup>

        <UFormGroup
          v-else-if="field.fieldType === 'number'"
          class="flex-1"
          :class="{hidden: field.hidden}"
          :required="field.required"
          :label="field.label"
          :description="field.description"
          :name="field.name">
          <UInput
            :placeholder="field.placeholder"
            type="number"
            v-model="state[field.name]"></UInput>
        </UFormGroup>

        <UFormGroup
          v-else-if="field.fieldType === 'multiple_checkboxes'"
          class="flex-1"
          :class="{hidden: field.hidden}"
          :required="field.required"
          :label="field.label"
          :description="field.description"
          :name="field.name">
          <UCheckbox
            class="w-fit border-none px-0 py-1 bg-transparent dark:bg-transparent backdrop-blur-none"
            v-for="option in field.options.sort((o) => o.displayOrder)"
            :label="option.label"
            :id="option.label + option.value"
            :value="option.value"
            :placeholder="field.placeholder"
            v-model="state[field.name]">
          </UCheckbox>
        </UFormGroup>

        <UFormGroup
          v-else-if="field.fieldType === 'dropdown'"
          class="flex-1"
          :class="{hidden: field.hidden}"
          :required="field.required"
          :label="field.label"
          :description="field.description"
          :name="field.name">
          <USelect
            :options="field.options"
            :placeholder="field.placeholder"
            option-attribute="label"
            v-model="state[field.name]">
          </USelect>
        </UFormGroup>

        <UFormGroup
          v-else-if="field.fieldType === 'radio'"
          class="flex-1"
          :class="{hidden: field.hidden}"
          :required="field.required"
          :label="field.label"
          :description="field.description"
          :name="field.name">
          <URadio
            v-for="option of field.options"
            :key="option.value"
            v-model="state[field.name]"
            v-bind="option" />
        </UFormGroup>

        <UFormGroup
          v-else-if="field.fieldType === 'datepicker'"
          class="flex-1"
          :class="{hidden: field.hidden}"
          :required="field.required"
          :label="field.label"
          :description="field.description"
          :name="field.name">
          <UPopover :popper="{placement: 'bottom-start'}" class="w-fit">
            <UButton
              variant="outline"
              icon="i-heroicons-calendar-days-20-solid"
              :label="
                state[field.name]
                  ? format(state[field.name], 'd MMM, yyy')
                  : t('form.datepicker.placeholder')
              " />
            <template #panel="{close}">
              <DatePicker v-model="state[field.name]" @close="close" />
            </template>
          </UPopover>
        </UFormGroup>

        <UFormGroup
          v-else
          class="flex-1"
          :class="{hidden: field.hidden}"
          :required="field.required"
          :label="field.label"
          :description="field.description"
          :name="field.name">
          <UInput
            :placeholder="field.placeholder"
            v-model="state[field.name]"></UInput>
        </UFormGroup>
      </template>
      <div v-else-if="group.richText" v-html="group.richText"></div>
    </div>

    <div class="flex flex-col gap-10">
      <p v-if="formDefinitionBase.legalConsentOptions.communicationConsentText">
        {{ formDefinitionBase.legalConsentOptions.communicationConsentText }}
      </p>
      <UFormGroup
        v-for="checkbox in formDefinitionBase.legalConsentOptions
          .communicationsCheckboxes"
        class="flex-1"
        :required="checkbox.required"
        :name="'communications.' + checkbox.subscriptionTypeId + ''">
        <UCheckbox
          class="w-fit"
          :label="checkbox.label"
          :required="checkbox.required"
          v-model="state.communications[checkbox.subscriptionTypeId]">
          <template #label>
            <div class="inline-block" v-html="checkbox.label"></div>
          </template>
        </UCheckbox>
      </UFormGroup>
      <p v-if="formDefinitionBase.legalConsentOptions.privacyText">
        {{ formDefinitionBase.legalConsentOptions.privacyText }}
      </p>
      <p v-if="formDefinitionBase.legalConsentOptions.consentToProcessText">
        {{ formDefinitionBase.legalConsentOptions.consentToProcessText }}
      </p>
    </div>

    <div class="col-span-2 self-end place-self-end">
      <button class="trimm-button" type="submit" value="Submit">
        <span class="pr-4">
          {{ formDefinitionBase.displayOptions.submitButtonText }}
        </span>
        <SvgoRightArrow />
      </button>
    </div>
  </UForm>
</template>

<style lang="scss" scoped>
:deep(h1) {
  @apply trimm-indent-10 lg:trimm-indent-20 uppercase font-gotham text-2xl lg:text-5xl whitespace-break-spaces;
}
</style>

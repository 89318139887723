<script lang="ts" setup>
const containerRef = ref(null);
const swiper = useSwiper(containerRef);
import {Pagination} from "swiper/modules";
const modules = [Pagination];
const props = withDefaults(
  defineProps<{
    eyebrow?: string;
    large?: boolean;
    quotes: string[];
  }>(),
  {
    large: false,
  },
);

// Computed property to get the actual color from the element
const computedColor = computed(() => {
  if (containerRef.value) {
    return getComputedStyle(containerRef.value).color || "#fff";
  }
  return "#fff";
});
const computedSvg = computed(() => {
  const encodedColor = computedColor.value;
  return `url("data:image/svg+xml,<svg width='90' height='90' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 90 90'><g fill='${encodedColor}'><path d='M0,18.3v53.3c0,3.4,2.6,6.1,6,6.1h6V12.2s-6,0-6,0C2.7,12.2,0,14.9,0,18.3Z'/><path d='M18,90h6c3.3,0,6-2.7,6-6.1v-6.1H12v6.1c0,3.4,2.7,6.1,6,6.1Z'/><path d='M24,0h-6c-3.3,0-6,2.7-6,6.1v6.1h17.9v-6.1c0-3.4-2.7-6.1-6-6.1Z'/></g><g fill='${encodedColor}'><path d='M84,12.2h-6s0,65.6,0,65.6h6c3.3,0,6-2.7,6-6.1V18.3c0-3.4-2.7-6.1-6-6.1Z'/><path d='M78,83.9v-6.1s-17.9,0-17.9,0v6.1c0,3.4,2.7,6.1,6,6.1h6c3.3,0,6-2.7,6-6.1Z'/><path d='M60,6.1v6.1s17.9,0,17.9,0v-6.1C78,2.7,75.3,0,72,0h-6c-3.3,0-6,2.7-6,6.1Z'/></g></svg>")`;
});
</script>

<template>
  <section class="inner-content-grid my-12">
    <h1 class="text-pumpkin uppercase font-bold text-center" v-if="eyebrow">
      {{ eyebrow }}
    </h1>

    <div
      class="col-span-4"
      :class="[
        large
          ? 'lg:col-span-10 lg:col-start-2'
          : 'lg:col-span-6 lg:col-start-4',
      ]">
      <client-only>
        <swiper-container
          ref="containerRef"
          :pagination="{clickable: true}"
          :autoplay="false"
          :space-between="16"
          :modules="modules"
          class="border-accollade"
          :speed="1000">
          <swiper-slide v-for="quote in quotes">
            <span
              class="inline-flex items-center text-center p-4"
              :class="{'lg:text-2xl lg:mx-24': large}">
              {{ quote }}
            </span>
          </swiper-slide>
        </swiper-container>
      </client-only>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.border-accollade {
  --pagination-offset: theme(spacing.12);

  border-style: solid;
  border-image-repeat: round;
  border-width: 6px;
  border-image-width: 12px;
  border-image-slice: 30;

  border-image-source: v-bind(computedSvg);

  margin-bottom: var(--pagination-offset);
  &::part(container) {
    overflow: visible;
    overflow-x: clip;
  }

  &::part(pagination) {
    transform: translateY(var(--pagination-offset));
  }
}

.grid {
  --quote-width: theme(spacing.2);
  grid-template-columns:
    minmax(var(--quote-width), auto)
    1fr
    minmax(var(--quote-width), auto);
}
</style>

<script lang="ts" setup>
import type {Asset, FromResolver} from "#magnolia-layer/types/magnolia-types";
import {Vue3Lottie} from "vue3-lottie";
import {isAsset} from "#magnolia-layer/types/api";
import {joinURL, withoutBase} from "ufo";
import type {ComponentInstance} from "vue";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

if (import.meta.client) {
  gsap.registerPlugin(ScrollTrigger);
}

type LottieAnimationComponentOptions = {
  assetLink?: FromResolver<Asset>;
  inheritColors?: boolean;
  width?: string;
  height?: string;
  type: "default" | "scrub";
  loop?: string; // only on default
  onViewportEntry?: boolean; // only on default
  scrubValue?: string; // only on scrub
  ariaLabel?: string;
};

const props = defineProps<LottieAnimationComponentOptions>();

const directLink = computed(() => {
  const asset = isAsset(props.assetLink) ? props.assetLink : null;
  if (!asset) {
    console.error("Provided property did not resolve", props.assetLink);
    return;
  }
  const runtimeConfig = useRuntimeConfig();
  const {host} = runtimeConfig.public.mgnl;
  return joinURL(host, withoutBase(asset["@link"], "/magnoliaAuthor"));
});

const scrubMode = props.type === "scrub";

const asCss = (value?: string, defaultValue?: string) => {
  if (!value) return defaultValue;
  return Number.isNaN(Number(value)) ? value : Number(value);
};

const assertNumber = (value?: string) => {
  const n = Number(value);
  if (Number.isNaN(n)) return undefined;
  return n;
};

const loopValue = (value?: string) => {
  const n = Number(value);
  if (Number.isNaN(n)) return undefined;
  if (n <= 0) return false;
  return n + 1;
};

const lottieRef = ref<ComponentInstance<typeof Vue3Lottie>>();
const onAnimationLoaded = () => {
  if (!scrubMode && !props.onViewportEntry) {
    return;
  }

  const lottie = lottieRef.value;
  if (!lottie) return;

  lottie.goToAndStop(0);

  const baseScrollTriggerVars: ScrollTrigger.Vars = {
    trigger: lottie.$el,
    start: "bottom bottom",
    toggleActions: "play none none reset",
  };

  if (!scrubMode) {
    const scrollTrigger = new ScrollTrigger({
      ...baseScrollTriggerVars,
      onEnter: () => {
        if (scrubMode) return;
        lottie.play();
      },
    });
    return;
  }

  const duration = lottie.getDuration(false);
  const state = {frame: 0};
  if (!duration) {
    console.error("Could not get Lottie duration.");
    return;
  }

  gsap.to(state, {
    frame: lottie.getDuration(true),
    duration: duration,
    onUpdate: () => {
      lottie.goToAndStop(state.frame - 1, true);
    },
    scrollTrigger: {
      ...baseScrollTriggerVars,
      scrub: assertNumber(props.scrubValue) ?? true,
    },
  });
};

const mgnlContext = useMagnoliaContext();
</script>

<template>
  <client-only>
    <NuxtErrorBoundary>
      <div :aria-label="ariaLabel ? ariaLabel : $t('aria.labels.lottie')">
        <Vue3Lottie
          v-if="directLink"
          ref="lottieRef"
          aria-hidden="true"
          :animationLink="directLink"
          :class="{'force-currentColor-svg': inheritColors}"
          :height="asCss(height, 'auto')"
          :loop="scrubMode ? undefined : loopValue(loop)"
          :width="asCss(width)"
          class="!my-12 lottie"
          @on-animation-loaded="onAnimationLoaded" />
      </div>
      <template #error="{error}">
        <UAlert
          v-if="mgnlContext.isMagnolia"
          icon="i-heroicons-exclamation-circle"
          color="pumpkin"
          title="Editor notice">
          <template #description>
            There seems to be something wrong with the selected file. Is it
            really a Lottie file?
            <UAlert class="font-[monospace] mt-4">
              <template #description>
                {{ error }}
              </template>
            </UAlert>
          </template>
        </UAlert>
        <template v-else>
          <!-- There seems to be something wrong with the selected file. Is it really a Lottie file? -->
        </template>
      </template>
    </NuxtErrorBoundary>
  </client-only>
</template>

<style lang="scss" scoped></style>

<script lang="ts" setup>
import AccordionItem from "~/components/AccordionItem.vue";
import {AccentColor} from "~/types/accentColor";
import {EditableArea} from "@magnolia/vue-editor";
import type {Area} from "#magnolia-layer/types/magnolia-types";

defineProps<{
  title?: string;
  description?: string;
  actions?: Area;
}>();

const accentColor = inject<AccentColor>("accentColor", AccentColor.teal);
</script>

<template>
  <AccordionItem :accent-color="accentColor" :title="title">
    <div class="apply-content-styles" v-interpolate v-html="description"></div>
    <EditableArea :content="actions" class="flex flex-wrap gap-4" />
  </AccordionItem>
</template>

<script lang="ts" setup>
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import {usePreferredReducedMotion} from "@vueuse/core";

if (import.meta.client) {
  gsap.registerPlugin(ScrollTrigger);
}

const speed = 100;
const ticker = ref<HTMLDivElement>();
const props = defineProps<{
  text?: string;
}>();

let anim: gsap.core.Tween | undefined;
let scrollTrigger: ScrollTrigger | undefined;
let gsapTicker: gsap.Callback | undefined;
const preference = usePreferredReducedMotion();

onMounted(() => {
  if (import.meta.client) {
    const _ticker = ticker.value!;

    const item = _ticker.children[0] as HTMLElement;
    const tickerWidth = item.offsetWidth;
    const initDuration = tickerWidth / speed;

    // Initialize the animation
    anim = gsap.to(_ticker.children, {
      duration: () =>
        preference.value === "reduce" ? initDuration * 10 : initDuration,
      xPercent: -100,
      ease: "none",
      onReverseComplete: () => {
        anim?.reverse(0);
      },
      onComplete: () => {
        anim?.restart();
      },
    });

    let targetDelta = 0; // Tracks the accumulated delta for smooth updates

    // ScrollTrigger to control the animation based on scroll velocity
    scrollTrigger = ScrollTrigger.create({
      trigger: "body",
      onUpdate: (self) => {
        if (preference.value === "reduce") return;
        const v = self.getVelocity();
        const delta = v / 100_000; // Calculate velocity-based delta
        targetDelta += delta;
        if (Math.abs(v) < 10 || !anim) return;
        anim[delta < 0 ? "reverse" : "play"](); // Play or reverse based on scroll direction
      },
    });

    // Smoothly update the animation progress
    gsapTicker = gsap.ticker.add(() => {
      if (!anim) return;
      const smoothedDelta = gsap.utils.interpolate(0, targetDelta, 0.1); // Smooth delta
      anim.progress((anim.progress() + smoothedDelta) % 1); // Update progress
      targetDelta *= 0.9; // Apply deceleration
    });
  }
});

onUnmounted(() => {
  if (anim) {
    anim.kill();
  }
  if (scrollTrigger) {
    scrollTrigger.kill();
  }
  if (gsapTicker) {
    gsap.ticker.remove(gsapTicker);
  }
});
</script>

<template>
  <section class="col-fullwidth my-12 whitespace-nowrap">
    <div ref="ticker" class="inline-block">
      <div
        class="will-change-transform inline-block pr-12 text-8xl"
        v-html="text"></div>
      <div
        aria-hidden="true"
        class="will-change-transform inline-block pr-12 text-8xl"
        v-html="text"></div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
section {
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}
</style>

<script lang="ts" setup>
/// <reference types="google.maps" />
const mapsApi = useRuntimeConfig().public.googleMaps.apiKey;
const props = defineProps<{
  title?: string;
}>();

const {onLoaded} = useScriptGoogleMaps({
  apiKey: mapsApi,
});

const map = ref();
onMounted(() => {
  onLoaded(async (instance) => {
    const maps = (await instance.maps) as any as typeof google.maps; // upstream google type issue
    const {AdvancedMarkerElement} = (await maps.importLibrary(
      "marker",
    )) as google.maps.MarkerLibrary;

    const lat = 52.2330052;
    const lng = 6.8909204;

    const gMap = new maps.Map(map.value, {
      center: {lat, lng},
      zoom: 16,
      mapId: "TRIMM_WEBSITE_MAP",
    });

    new AdvancedMarkerElement({
      map: gMap,
      position: {
        lat,
        lng,
      },
    });
  });
});
</script>

<template>
  <section class="inner-content-grid my-12">
    <div class="col-span-12" v-if="title">
      <h2 class="text-center text-3xl">{{ title }}</h2>
    </div>

    <div class="col-span-12 aspect-[21/9]">
      <div ref="map" class="h-full" />
    </div>
  </section>
</template>

<style lang="scss" scoped></style>

// Adapted from https://mokkapps.de/vue-tips/check-if-slot-is-empty
// Adapted from https://github.com/vuejs/vue-next/blob/ca17162e377e0a0bf3fae9d92d0fdcb32084a9fe/packages/runtime-core/src/helpers/renderSlot.ts#L77
import {Fragment, Comment, Text, type VNode} from "vue";

const isVNodeEmpty = (vNodes: Array<VNode>): boolean => {
  return vNodes.every((node: VNode) => {
    if (node.type === Comment) {
      return true;
    }

    if (
      node.type === Text &&
      typeof node.children === "string" &&
      !node.children.trim()
    ) {
      return true;
    }

    return (
      node.type === Fragment && isVNodeEmpty(node.children as Array<VNode>)
    );
  });
};
export default isVNodeEmpty;

import type {Asset} from "#magnolia-layer/types/magnolia-types";
import type {ImageOptions} from "@nuxt/image";
import {absoluteIfPossible as _absoluteIfPossible} from "#base-layer/utils/absolute-if-possible";

// TODO: Move to magnolia Layer with out renameAsset... yet again 🙄
export const absoluteIfPossible = (image: Asset, aspectRatio: number, imageOptions?: ImageOptions) => {
    const img = useImage();
    return _absoluteIfPossible(img(image['@link'], {
        extract: imageToExtractionArea(image, aspectRatio),
        ...imageOptions
    }));
}
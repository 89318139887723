<script setup lang="ts"></script>

<template>
  <button
    :aria-label="$t('aria.labels.video.playButton')"
    class="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-pumpkin text-white rounded-full aspect-square text-6xl p-3 transition-all group-hover:bg-pumpkin-600 group-hover:border-pumpkin-600 group-hover:scale-110">
    <SvgoRightChevron class="mb-0 translate-x-1" />
  </button>
</template>

<style scoped lang="scss">
svg {
  margin-bottom: 0;
}
</style>

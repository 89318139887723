import type {MetaAttrs} from "@nuxtjs/i18n";
import {joinURL, parseURL} from "ufo";
import type {UseHeadInput} from "unhead";
import {MergeHead} from "zhead";
import {useServerRequestUrl} from "~/composables/useServerRequestUrl";

export const useI18nMultiTenantHead = <T extends MergeHead>(
  input: UseHeadInput<T>,
  options: {
    forcedCanonical?: string;
    languages?: string[];
  },
) => {
  const serverRequestUrl = useServerRequestUrl();
  const i18nHead = useLocaleHead({
    addSeoAttributes: {},
  });
  const link = [
    ...(i18nHead.value.link.map((l: MetaAttrs) => {
      if (options.forcedCanonical && l.rel === "canonical") {
        return {
          ...l,
          href: options.forcedCanonical,
        };
      }
      return {
        ...l,
        href: l.href.startsWith("http")
          ? l.href
          : joinURL(serverRequestUrl.value.origin, l.href),
      };
    }) || []),
  ].filter((l) => {
    const hrefLang = l["hreflang"];
    if (hrefLang && options.languages) {
      return options.languages.includes(hrefLang);
    }
    return true;
  });

  const meta = [...(i18nHead.value.meta || [])].filter((m) => {
    if (m.property?.includes("og:locale") && m.content && options.languages) {
      return options.languages.includes(m.content);
    }
    return true;
  });

  return useHead({
    ...input,
    htmlAttrs: {
      lang: i18nHead.value.htmlAttrs!.lang,
    },
    link: link,
    meta: meta,
  });
};

<script lang="ts" setup>
import type {Page} from "#magnolia-layer/types/magnolia-types";
import {joinURL} from "ufo";
import Slider from "~/components/Slider.vue";

const props = defineProps<{
  title?: string;
  pages?: string[];
}>();

const runtimeConfig = useRuntimeConfig();
const pagesApi = runtimeConfig.public.mgnl.apiPages;
let pageData: Page[] = [];

if (props.pages) {
  for (const page of props.pages) {
    const response = await useMagnoliaFetch<Page>(
      joinURL(pagesApi, stripPagination(page ?? "")),
    );

    if (response.data.value) {
      pageData.push(response.data.value);
    }
  }
}

const getPageLink = (path: string): string | undefined => {
  if (path) {
    return stripPrefix(path);
  }
  return undefined;
};

const getFirstTag = (page: Page): string | undefined => {
  if (!page) return undefined;
  const tags: string[] = page["mgnl:tags"];
  if (!tags) return undefined;
  if (tags.length === 0) return undefined;
  return tags[0];
};
</script>

<template>
  <section class="col-fullwidth inner-content-grid my-12">
    <h1 class="col-span-4 lg:col-span-12 text-center uppercase text-2xl">
      {{ title }}
    </h1>

    <div class="lg:hidden relative col-span-4">
      <Slider>
        <template v-slot:slides>
          <swiper-slide class="col-span-4 flex pb-16" v-for="page in pageData">
            <article :aria-label="page.title" class="relative w-full h-full overflow-hidden">
              <NuxtLinkLocale :href="getPageLink(page['@path'])" target="_self">
                <MagnoliaPicture :asset="page.image" class="self-end" />
                <div
                  class="bg-black absolute right-0 bottom-0 pt-4 pl-10 pb-4 pr-20">
                  <span class="text-pumpkin uppercase font-bold">
                    {{ getFirstTag(page) }}
                  </span>
                  <h2>{{ page.title }}</h2>
                </div>
              </NuxtLinkLocale>
            </article>
          </swiper-slide>
        </template>
      </Slider>
    </div>

    <div class="lg:flex gap-4">
      <article
        class="relative overflow-hidden hidden lg:flex flex-1"
        :aria-label="page.title"
        v-for="page in pageData">
        <NuxtLinkLocale
          class="w-full h-full"
          :href="getPageLink(page['@path'])"
          target="_self">
          <MagnoliaPicture :asset="page.image" class="self-end" />
          <div class="bg-black absolute right-0 bottom-0 pt-4 pl-10 pb-4 pr-20">
            <span class="text-pumpkin uppercase font-bold">
              {{ getFirstTag(page) }}
            </span>
            <h2>{{ page.title }}</h2>
          </div>
        </NuxtLinkLocale>
      </article>
    </div>
  </section>
</template>

<style lang="scss" scoped>
:deep(img) {
  @apply object-cover transition-all w-full h-full;
  aspect-ratio: 4 / 3;
}

a:hover {
  :deep(img) {
    @apply hover:scale-105;
  }
}
</style>

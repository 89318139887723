<script setup lang="ts">
import type {Button} from "~/types/button";

const props = defineProps<Button>();
</script>
<template>
  <TrimmButton :button="props"></TrimmButton>
</template>

<style scoped lang="scss"></style>

<script lang="ts" setup>
import TrimmButton from "~/components/TrimmButton.vue";
import type {Button} from "~/types/button";
import isVNodeEmtpy from "~/utils/isVNodeEmtpy";

const props = withDefaults(
  defineProps<{
    eyebrow?: string;
    title?: string;
    body?: string;
    button?: Button;
    indent?: boolean;
    uppercase?: boolean;
  }>(),
  {
    indent: true,
    uppercase: false,
  },
);

const slots = useSlots();
const hasActionsSlotWithContent = computed(() => {
  const slotContent = slots.actions ? slots.actions() : [];
  return !isVNodeEmtpy(slotContent);
});
</script>

<template>
  <div class="text-block break-inside-avoid">
    <span
      v-if="eyebrow"
      class="text-pumpkin uppercase font-bold mb-2 inline-block"
      :class="{'trimm-indent-10 lg:trimm-indent-20': indent}">
      {{ eyebrow }}
    </span>

    <h1
      v-if="title"
      class="font-gotham break-words text-2xl lg:text-5xl whitespace-break-spaces mb-6"
      :class="{
        'trimm-indent-10 lg:trimm-indent-20': indent,
        uppercase: uppercase,
      }">
      {{ title }}
    </h1>

    <div
      class="apply-content-styles"
      :class="{'lg:ml-20': indent}"
      v-if="body"
      v-html="body"
      v-interpolate />

    <TrimmButton
      v-if="button && button.label"
      :button="button"
      class="mt-6"
      :class="{'lg:ml-20': indent}" />

    <div
      class="mt-6"
      :class="{'lg:ml-20': indent}"
      v-if="hasActionsSlotWithContent">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.text-block:has(+ .text-block) {
  @apply mb-10 lg:mb-12;
}
</style>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
<script lang="ts" setup>
import TextBlock from "~/components/TextBlock.vue";
import type {
  Area,
  Asset,
  FocalPointAsset,
  FromResolver,
} from "#magnolia-layer/types/magnolia-types";
import TrimmButton from "~/components/TrimmButton.vue";
import {SvgoDownArrow} from "#components";
import type {AccentColor} from "~/types/accentColor";
import {EditableArea} from "@magnolia/vue-editor";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import {imageToExtractionArea} from "#imports";

if (import.meta.client) {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
}

const props = defineProps<{
  eyebrow?: string;
  title?: string;
  body?: string;
  actions?: Area;
  image: FromResolver<Asset | FocalPointAsset>;
  background?: AccentColor;
}>();

const hasBackground = computed(() => {
  return props.background !== undefined && props.background !== "transparent";
});

const scrollDown = () => {
  const elementsByName = document.getElementsByTagName("main");
  if (!elementsByName) return;
  const element = elementsByName.item(0);
  if (!element) return;
  const y = element.offsetTop;

  const logoItems = document.getElementsByClassName("logo");
  if (!logoItems) return;
  const logo = logoItems.item(0);
  if (!logo) return;
  const logoHeight = logo.clientHeight;

  gsap.to(window, {
    duration: 1,
    scrollTo: {
      y: y - logoHeight * 2,
    },
    ease: "Power1.easeInOut",
  });
};
</script>

<template>
  <section class="inner-content-grid my-12 z-[2]">
    <div
      :style="{'--trimm-button-hover-color': `var(--color-${background})`}"
      class="col-span-4 row-start-1 col-start-1 lg:col-end-7 px-12 py-8"
      :class="[hasBackground ? `bg-${background} trimm-cutout-br-12` : '']">
      <TextBlock :eyebrow="eyebrow" :title="title" :body="body" uppercase>
        <template #actions>
          <EditableArea :content="actions" class="flex flex-wrap gap-4" />
        </template>
      </TextBlock>
      <TrimmButton
        @click.prevent="scrollDown"
        :icon="SvgoDownArrow"
        class="down mt-6 lg:ml-20 hover:cursor-pointer" />
    </div>
    <div
      class="col-span-4 h-fit lg:col-span-8 lg:col-start-5 lg:-translate-y-12 lg:row-start-1 z-[-1] lg:-mt-16 trimm-cutout-bl-6 lg:trimm-cutout-bl-12"
      :class="[hasBackground ? '-translate-y-22' : '']">
      <MagnoliaPicture
        :asset="image"
        format="avif,webp"
        :quality="100"
        :modifiers="{
          extract: imageToExtractionArea(image, 4 / 3),
        }"
        class=""
        densities="1x 2x"
        sizes="200vw sm:100vw">
      </MagnoliaPicture>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.bg-teal,
.bg-patina,
.bg-pumpkin,
.bg-violet,
.bg-scarlet {
  :deep(span) {
    @apply text-white;
  }
}

:deep(p) {
  @apply lg:mr-28;
}

:deep(h1) {
  @apply font-thin;
}

:deep(svg) {
  @apply h-7;
}

:deep(.trimm-button.down) {
  @apply hidden lg:inline-block pl-2 pr-2;

  &:hover {
    svg {
      @apply translate-y-2 translate-x-0;
    }
  }
}
</style>

<script lang="ts" setup>
const containerRef = ref(null);
const swiper = useSwiper(containerRef, {
  pagination: {
    clickable: true,
  },
});
</script>

<template>
  <swiper-container ref="containerRef" class="grid">
    <slot name="slides" />
  </swiper-container>

  <button
    @click="swiper.prev()"
    id="prev"
    class="bg-white text-nightblue text-xl p-2 rounded-full aspect-square absolute left-2 top-[200px] z-10">
    <SvgoLeftChevron />
  </button>
  <button
    @click="swiper.next()"
    id="next"
    class="bg-white text-nightblue text-xl p-2 rounded-full aspect-square absolute right-2 top-[200px] z-10">
    <SvgoRightChevron />
  </button>
</template>

<style lang="scss" scoped>
.page {
  :deep(h1) {
    @apply text-4xl;
  }

  :deep(picture img) {
    @apply w-full mb-4;
  }

  &:nth-child(odd) {
    :deep(picture img) {
      @apply trimm-cutout-br-12;
    }
  }

  &:nth-child(even) {
    :deep(picture img) {
      @apply trimm-cutout-tl-12;
    }
  }
}

button :deep(svg) {
  margin-bottom: 0;
}
</style>

<script setup lang="ts">
import {z} from "zod";
import type {FormSubmitEvent} from "#ui/types";
import {SvgoRightArrow} from "#components";
import type {Component} from "vue";

const {t, locale: currentLocale} = useI18n();

const props = defineProps<{
  metadata: Component;
  portalId: string;
  formId: string;
  title: string;
  buttonLabel: string;
  optInDisclaimer: string;
  languageValue?: string;
}>();

const schema = z.object({
  email: z
    .string()
    .min(1, {message: t("signUpForm.errors.required")})
    .email({message: t("signUpForm.errors.invalidEmail")}),
  termsAndPolicy: z.literal(true, {
    errorMap: (err, ctx) => {
      return {
        message:
          err.code === "invalid_literal"
            ? t("signUpForm.errors.consent")
            : ctx.defaultError,
      };
    },
  }),
  newsletter: z.boolean(),
  language: z.string().min(1),
  "g-recaptcha-response": z.string().optional(),
});

let state = reactive({
  email: undefined,
  termsAndPolicy: undefined,
  newsletter: true,
  language: props.languageValue
    ? props.languageValue
    : currentLocale.value.toUpperCase(),
  "g-recaptcha-response": undefined,
});

let success: Ref<boolean | undefined> = ref(undefined);

type Schema = z.output<typeof schema>;

async function onSubmit(event: FormSubmitEvent<Schema>) {
  success.value = undefined;

  executeRecaptcha("submit").then(async (token) => {
    if (token) {
      event.data["g-recaptcha-response"] = token;
    }

    try {
      const response = await $fetch("/api/form/signup", {
        query: {
          portalId: props.portalId,
          formId: props.formId,
        },
        method: "POST",
        body: event.data,
      });

      if (response) {
        success.value = true;
      }
    } catch (e) {
      success.value = false;
    } finally {
      Object.assign(state, {
        email: undefined,
        termsAndPolicy: undefined,
        "g-recaptcha-response": undefined,
      });
    }
  });
}

const {executeRecaptcha} = useRecaptcha();
</script>

<template>
  <div class="inner-content-grid">
    <UAlert
      v-if="success"
      icon="i-heroicons-check-circle-solid"
      color="patina"
      variant="solid"
      :title="$t('signUpForm.submitted.title')"
      :description="$t('signUpForm.submitted.description')" />

    <UAlert
      v-else-if="success === false"
      icon="i-heroicons-x-circle-solid"
      color="scarlet"
      variant="solid"
      :title="$t('signUpForm.failed.title')"
      :description="$t('signUpForm.failed.description')" />
  </div>
  <UForm :schema="schema" :state="state" @submit="onSubmit" class="">
    <h1 id="contact-title" class="font-bold">
      {{ title }}
    </h1>
    <UInput class="hidden" v-model="state.language"></UInput>
    <UCheckbox class="hidden" name="newsletter" v-model="state.newsletter" />

    <UFormGroup name="email" class="mt-4">
      <UInput
        :placeholder="$t('signUpForm.form.email.placeholder')"
        required
        v-model="state.email"></UInput>
    </UFormGroup>
    <UFormGroup name="termsAndPolicy">
      <UCheckbox
        name="termsAndPolicy"
        class="border-x-0 border-t-0 pl-0 bg-transparent dark:bg-transparent"
        :label="optInDisclaimer"
        v-model="state.termsAndPolicy" />
    </UFormGroup>
    <div class="col-span-2 self-end place-self-end mt-4">
      <button class="trimm-button" type="submit" value="Submit">
        <span class="pr-4"> {{ buttonLabel }} </span>
        <SvgoRightArrow />
      </button>
    </div>
  </UForm>
</template>

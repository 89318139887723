<script lang="ts" setup>
import type {
  Area,
  FromResolver,
  Page,
} from "#magnolia-layer/types/magnolia-types";
import TextBlock from "~/components/TextBlock.vue";
import type {Button} from "~/types/button";
import Slider from "~/components/Slider.vue";
import {isPage} from "#magnolia-layer/types/api";
import {EditableArea} from "@magnolia/vue-editor";
import {imageToExtractionArea} from "#imports";
import OverviewEntry from "~/components/OverviewEntry.vue";

const props = defineProps<{
  metadata: Area;
  title?: string;
  body?: string;
  actions?: Area;
  buttonLabel?: string;
  pages?: FromResolver<Page>[];
}>();

const pageData = computed<Page[]>(() => {
  return (
    props.pages?.filter((value): value is Page => {
      return isPage(value);
    }) ?? []
  );
});
</script>

<template>
  <section class="inner-content-grid my-12">
    <TextBlock
      :title="title"
      :body="body"
      uppercase
      class="col-span-4 lg:col-span-5 row-start-1 mb-12">
      <template #actions>
        <EditableArea :content="actions" class="flex flex-wrap gap-4" />
      </template>
    </TextBlock>
    <div class="lg:hidden relative">
      <Slider>
        <template v-slot:slides>
          <swiper-slide v-for="(page, i) in pageData" class="mb-8">
            <div
              class="page col-span-4 lg:col-span-5"
              :class="[i % 2 !== 0 ? 'lg:mt-[-100%]' : 'lg:col-start-8']">
              <article>
                <MagnoliaPicture
                  :asset="page.image"
                  class="h-[400px] inline-block" />
                <TextBlock
                  :title="page.title"
                  :body="page.description"
                  :eyebrow="getFirstTag(page)"
                  :indent="false" />
                <NuxtLinkLocale
                  :href="getPageLink(page['@path'])"
                  target="_self"
                  class="trimm-button mt-4">
                  <span class="pr-4" v-if="buttonLabel">
                    {{ buttonLabel }}
                  </span>
                  <span class="pr-4">
                    {{ $t("pageOverview.item.button.label") }}
                  </span>
                  <SvgoRightArrow />
                </NuxtLinkLocale>
              </article>
            </div>
          </swiper-slide>
        </template>
      </Slider>
    </div>
    <OverviewEntry
      v-for="(page, i) in pageData"
      :class="[i % 2 !== 0 ? '' : 'lg:col-start-8']"
      :page
      :button-label="buttonLabel"
      class="page col-span-4 lg:col-span-5 hidden lg:block row-span-2 mb-12 h-fit">
    </OverviewEntry>
  </section>
</template>

<style lang="scss" scoped>
.page {
  :deep(h1) {
    @apply text-4xl;
  }

  :deep(picture img) {
    @apply mb-4;
  }

  &:nth-child(odd) {
    :deep(picture img) {
      @apply trimm-cutout-bl-12;
    }
  }

  &:nth-child(even) {
    :deep(picture img) {
      @apply trimm-cutout-tr-12;
    }
  }
}

button :deep(svg) {
  margin-bottom: 0;
}

:deep(img) {
  @apply w-[inherit] h-[inherit] object-cover lg:h-[unset] lg:w-full;
}

:deep(picture) {
  @apply w-full;
}
</style>

<script lang="ts" setup>
import type {Ref} from "vue";
import {AccentColor} from "~/types/accentColor";
import {EditableArea} from "@magnolia/vue-editor";
import gsap from "gsap";
import type {Area} from "#magnolia-layer/types/magnolia-types";

const props = withDefaults(
  defineProps<{
    eyebrow?: string;
    title?: string;
    body?: string;
    accentColor?: AccentColor;
    items?: Area;
    actions?: Area;
  }>(),
  {
    accentColor: AccentColor.teal,
  },
);

const section: Ref<HTMLElement | undefined> = ref(undefined);

provide("accentColor", props.accentColor);
provide("uuid", props.items ? props.items["@id"] : undefined);

onMounted(() => {
  if (!section.value) return;
  section.value
    .querySelectorAll<HTMLDetailsElement>("details[name]")
    .forEach(($details) => {
      const summary = $details.querySelector("summary");
      if (!summary) return;

      if (!$details.open) {
        gsap.set($details, {
          height: summary.offsetHeight,
        });
      } else {
        gsap.set($details, {
          height: $details.offsetHeight,
        });
      }

      $details.addEventListener("toggle", (e: Event) => {
        if (!(e instanceof ToggleEvent)) {
          console.warn(
            "Incoming event is not toggle event. Cannot animate accordion item.",
            e,
          );
          return;
        }
        const ev = e as ToggleEvent;

        e.preventDefault();

        if (ev.newState == "open") {
          $details.setAttribute("aria-expanded", "true");
          gsap.set($details, {clearProps: "height"});
          gsap.from($details, {
            duration: 0.2,
            height: summary.offsetHeight,
          });
        } else {
          $details.setAttribute("aria-expanded", "false");
          gsap.to($details, {
            duration: 0.2,
            height: summary.offsetHeight,
            onComplete: () => {
              gsap.set($details, {clearProps: "height"});
            },
          });
        }
      });
    });
});
</script>

<template>
  <section ref="section" class="content-grid col-span-full my-12">
    <div class="inner-content-grid my-12">
      <div>
        <TextBlock :eyebrow="eyebrow" :title="title" :body="body" uppercase>
          <template #actions>
            <EditableArea :content="actions" class="flex flex-wrap gap-4" />
          </template>
        </TextBlock>
      </div>
    </div>
    <EditableArea :content="items" />
  </section>
</template>

<script lang="ts" setup>
import {parseUrl} from "~/utils/video/videoIdMatcher";
import YoutubePlayer from "~/components/video/YoutubePlayer.vue";
import VimeoPlayer from "~/components/video/VimeoPlayer.vue";
import type {
  FocalPointAsset,
  FromResolver,
} from "#magnolia-layer/types/magnolia-types";

const {videoUrl, image} = defineProps<{
  videoUrl: string;
  image?: FromResolver<FocalPointAsset>;
}>();
const urlParsed = videoUrl ? parseUrl(videoUrl) : undefined;
const extract = imageToExtractionArea(image, 16 / 9);
</script>

<template>
  <section class="inner-content-grid my-12">
    <div class="col-span-4 lg:col-span-10 lg:col-start-2 isolate group">
      <YoutubePlayer
        v-if="urlParsed?.platform === 'youtube'"
        :video-id="urlParsed.id">
        <MagnoliaPicture
          v-if="image"
          :asset="image"
          loading="lazy"
          :modifiers="{
            extract,
          }"
          format="avif,webp"
          class="w-full *:w-full"
          :quality="100"
          densities="1x 2x"></MagnoliaPicture>
      </YoutubePlayer>
      <VimeoPlayer
        v-else-if="urlParsed?.platform === 'vimeo'"
        :id="urlParsed.id">
        <MagnoliaPicture
          v-if="image"
          :asset="image"
          loading="lazy"
          :modifiers="{
            extract,
          }"
          format="avif,webp"
          class="w-full *:w-full"
          :quality="100"
          densities="1x 2x"></MagnoliaPicture>
      </VimeoPlayer>
      <div v-else class="grid place-items-center">
        <div class="flex flex-col gap-4 text-center">
          <span class="font-bold font-sans"
            >It would seem this video is not be supported</span
          >
          <span>{{ videoUrl ?? typeof videoUrl }}</span>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped></style>

<script lang="ts" setup>
const props = defineProps<{
  textFront?: string;
  textMiddle?: string;
  textEnd?: string;
}>();

const label = computed(() => {
  let lbl: string = '';
  if (props.textFront) lbl = lbl + props.textFront;
  if (props.textMiddle) lbl = lbl + props.textMiddle;
  if (props.textEnd) lbl = lbl + props.textEnd;
  return lbl;
})
</script>

<template>
  <section class="inner-content-grid my-12">
    <h1 :aria-label="label"
      class="lowercase col-span-4 lg:col-span-10 lg:col-start-2 font-trimm text-6xl lg:text-[9em] xl:text-[11em] tracking-widest text-center">
      <span v-if="textFront" inert class="font-light">{{ textFront }}</span>
      <span v-if="textMiddle" inert>{{ textMiddle }}</span>
      <span v-if="textEnd" inert class="font-medium">{{ textEnd }}</span>
    </h1>
  </section>
</template>

<style lang="scss" scoped></style>

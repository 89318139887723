<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
<script lang="ts" setup>
import TextBlock from "~/components/TextBlock.vue";
import {EditableArea} from "@magnolia/vue-editor";
import type {Area} from "#magnolia-layer/types/magnolia-types";

const props = withDefaults(
  defineProps<{
    eyebrow?: string;
    title?: string;
    body?: string;
    actions?: Area;
    indent?: boolean;
  }>(),
  {},
);
</script>

<template>
  <TextBlock :eyebrow="eyebrow" :title="title" :body="body" :indent="indent">
    <template #actions>
      <EditableArea :content="actions" class="flex flex-wrap gap-4" />
    </template>
  </TextBlock>
</template>

<style lang="scss" scoped></style>

<script setup lang="ts">
import {getYouTubeThumbnail} from "~/utils/scripts/youtube";
import PlayerAwaitingLoad from "~/components/video/PlayerAwaitingLoad.vue";
import isVNodeEmtpy from "~/utils/isVNodeEmtpy";

const {videoId} = defineProps<{
  videoId: string;
}>();

const imageMaxRes = getYouTubeThumbnail(videoId, "maxresdefault");
const imageFallback = getYouTubeThumbnail(videoId, "hqdefault");

const slots = useSlots();

const hasDefaultSlotWithContent = computed(() => {
  const slotContent = slots.default ? slots.default() : [];
  return !isVNodeEmtpy(slotContent);
});
</script>

<template>
  <ScriptYouTubePlayer
    :video-id="videoId"
    :player-vars="{
      autoplay: 1,
      playsinline: 1,
      rel: 0, // Show videos from same channel as related videos
    }"
    class="rounded-[1rem] overflow-hidden relative !w-auto !aspect-video">
    <template #placeholder>
      <template v-if="hasDefaultSlotWithContent">
        <slot></slot>
      </template>
      <img
        v-else
        class="w-full"
        loading="lazy"
        :src="imageMaxRes"
        :data-fallback-src="imageFallback"
        onload="if (this.naturalWidth === 120 && this.naturalHeight === 90) { (this.src = this.dataset.fallbackSrc) }"
        alt="" />
    </template>
    <template #awaitingLoad>
      <PlayerAwaitingLoad></PlayerAwaitingLoad>
    </template>
  </ScriptYouTubePlayer>
</template>

<script lang="ts" setup>
import type {LocaleObject} from "@nuxtjs/i18n/dist/module";
import {computed} from "vue";
import type {NavigationRoot} from "#magnolia-layer/types/api";
import {resolveNavigation, resolveNavigationReference} from "#imports";
import type {Area} from "#magnolia-layer/types/magnolia-types";
import {EditableArea} from "@magnolia/vue-editor";

const {locales, locale: currentLocale} = useI18n();
const switchLocalePath = useSwitchLocalePath();
const availableLocales = computed(() => {
  return (locales.value as LocaleObject[]).filter((locale) => {
    return useMagnoliaLanguages().includes(locale.code);
  });
});

interface Props {
  signUpForm: Area;
  companyAddress: string;
  companyAddress2: string;
  companyEmail: string;
  companyName: string;
  companyPhone: string;
  facebookUsername: string;
  instagramUsername: string;
  linkedInUsername: string;
  twitterUsername: string;
  primaryNavigationRoot: string;
  secondaryNavigationRoot: string;
}

const props = defineProps<Props>();

const primaryNavRoot: Ref<NavigationRoot | null> =
  await resolveNavigationReference(props.primaryNavigationRoot);
const secondaryNavRoot: Ref<NavigationRoot | null> =
  await resolveNavigationReference(props.secondaryNavigationRoot);
const primaryNavItems = computed(() => {
  return resolveNavigation(primaryNavRoot.value);
});
const secondaryNavItems = computed(() => {
  return resolveNavigation(secondaryNavRoot.value);
});

const dailableTel = computed(() => {
  if (!props.companyPhone) return "";
  return "tel:".concat(props.companyPhone.replace(/[()\-\()\+\s]/g, ""));
});
const emailLink = computed(() => {
  if (!props.companyEmail) return "";
  return "mailto:".concat(props.companyEmail);
});

const facebookLink = computed(() => {
  return `https://www.facebook.com/${props.facebookUsername}`;
});

const instagramLink = computed(() => {
  return `https://www.instagram.com/${props.instagramUsername}`;
});

const linkedInLink = computed(() => {
  return `https://www.linkedin.com/in/${props.linkedInUsername}`;
});

const twitterLink = computed(() => {
  return `https://x.com/${props.twitterUsername}`;
});
</script>

<template>
  <div
    class="bg-gradient-to-t from-trueblack col-span-full content-grid border-t border-teal pb-10">
    <div class="col-span-full mb-10 text-5xl">
      <SvgoMonogramWhite aria-label="TRIMM Logo Monogram" />
    </div>
    <div class="inner-content-grid" v-cms-edit>
      <nav class="col-span-2 flex flex-col link-animation lg:col-span-3" role="navigation">
        <h1 class="font-bold mb-5">
          {{ $t("footer.titles.contact") }}
        </h1>
        <address class="flex flex-col font-normal not-italic mb-5">
          <span v-if="companyName">{{ companyName }}</span>
          <span v-if="companyAddress">{{ companyAddress }}</span>
          <span v-if="companyAddress2">{{ companyAddress2 }}</span>
        </address>
        <ul v-if="companyPhone || companyEmail">
          <li v-if="companyPhone">
            <a :href="dailableTel">{{ companyPhone }}</a>
          </li>
          <li v-if="companyEmail">
            <a :href="emailLink">{{ companyEmail }}</a>
          </li>
        </ul>
      </nav>
      <nav class="hidden col-span-2 link-animation lg:flex lg:flex-col lg:col-span-2">
        <h1 class="font-bold mb-5">
          {{ $t("footer.titles.navigation") }}
        </h1>
        <ul>
          <li v-for="item in primaryNavItems">
            <navigation-item :item="item" />
          </li>
        </ul>
      </nav>
      <nav
        class="col-span-2 flex flex-col link-animation lg:col-span-2"
        role="navigation"
        aria-labelledby="language-switcher">
        <h1 id="language-switcher" class="font-bold mb-5">
          {{ $t("footer.titles.language") }}
        </h1>
        <ul>
          <li v-for="locale in availableLocales">
            <NuxtLink
              :href="switchLocalePath(locale.code)"
              :hrefLang="locale.language"
              >{{ locale.name }}
            </NuxtLink>
          </li>
        </ul>
      </nav>
      <div class="col-span-full flex flex-col sm:grid sm:grid-cols-4 lg:flex lg:flex-col gap-10 lg:col-span-5">
        <nav class="sm:col-span-2">
          <h1 id="social-links" class="font-bold mb-5">
            {{ $t("footer.titles.followUs") }}
          </h1>
          <ul
            class="flex flex-row text-5xl gap-4 justify-center md:justify-normal lg:text-3xl">
            <li v-if="facebookUsername && facebookLink">
              <NuxtLink target="_blank" :to="facebookLink">
                <SvgoFacebook />
              </NuxtLink>
            </li>
            <li v-if="linkedInUsername && linkedInLink">
              <NuxtLink target="_blank" :to="linkedInLink">
                <SvgoLinkedIn />
              </NuxtLink>
            </li>
            <li v-if="instagramUsername && instagramLink">
              <NuxtLink target="_blank" :to="instagramLink">
                <SvgoInstagram />
              </NuxtLink>
            </li>
            <li v-if="twitterUsername && twitterLink">
              <NuxtLink target="_blank" :to="twitterLink">
                <SvgoTwitterX />
              </NuxtLink>
            </li>
          </ul>
        </nav>
        <EditableArea class="sm:col-span-2" :content="signUpForm" />
      </div>
      <nav
        role="navigation"
        aria-label="Secondary Footer Navigation"
        class="col-span-4 lg:col-span-full">
        <ul
          class="flex flex-row gap-4 text-sm justify-center text-teal  lg:justify-start">
          <li v-for="item in secondaryNavItems">
            <navigation-item :item="item" class="hover:underline hover:underline-offset-4"/>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<style scoped lang="scss">
.link-animation a {
  display: inline-block;
  text-decoration: none;
  position: relative;
  transition: all .125s ease-in-out;
  margin-bottom: 2px;

  &:before {
    content: " ";
    display: block;
    position: absolute;
    border-bottom: solid 2px #f37123;
    bottom: 0;
    width: 0%;
    left: 0;
    transition: all .125s ease-in-out;
  }

  &:hover {
    &:before {
      width: 100%;
    }
  }
}
</style>

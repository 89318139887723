<script lang="ts" setup>
import TextBlock from "~/components/TextBlock.vue";
import {EditableArea} from "@magnolia/vue-editor";
import type {Area} from "#magnolia-layer/types/magnolia-types";
import {joinURL, withProtocol} from "ufo";

const props = defineProps<{
  eyebrow?: string;
  eyebrowRight?: string;
  title?: string;
  body?: string;
  actions?: Area;
  shareText: string;
}>();

const router = useRouter();
const currentRoute = router.currentRoute;
const requestURL = useRequestURL();
const protocol = requestURL.protocol;
const host = requestURL.host;

const url = joinURL(withProtocol(host, protocol), currentRoute.value.fullPath);

const shareableTwitterLink = computed(() => {
  return `https://twitter.com/share?text=${props.shareText}&url=${url}`;
});
const shareableFacebookLink = computed(() => {
  return `https://www.facebook.com/sharer/sharer.php?u=${url}&t=${props.shareText}`; //TODO: Check if we have an facebook app id. (We could let them fill it in through the dialog?) (https://developers.facebook.com/docs/sharing/reference/feed-dialog)
});
const shareableLinkedinLin = computed(() => {
  return `https://www.linkedin.com/sharing/share-offsite/?text=${props.shareText}&url=${url}`;
});
</script>

<template>
  <section class="my-12 inner-content-grid text-center lg:text-left">
    <div class="col-span-6 order-2 lg:order-1">
      <TextBlock :eyebrow="eyebrow" :indent="false" :body="body">
        <template #actions>
          <EditableArea :content="actions" class="flex flex-wrap gap-4" />
        </template>
      </TextBlock>
    </div>
    <div class="col-span-6 justify-self-center lg:order-2 lg:justify-self-end">
      <span class="text-pumpkin uppercase font-bold mb-6 inline-block">
        {{ eyebrowRight }}
      </span>
      <div class="flex text-4xl justify-between">
        <NuxtLink
          :aria-label="$t('aria.labels.share.email')"
          :href="`mailto:?subject=Check this out&body=${shareText}: ${url}`">
          <SvgoEmailIcon />
        </NuxtLink>
        <NuxtLink
          :aria-label="$t('aria.labels.share.facebook')"
          :href="shareableFacebookLink"
          target="_blank">
          <SvgoFacebook />
        </NuxtLink>
        <NuxtLink
          :aria-label="$t('aria.labels.share.linkedIn')"
          :href="shareableLinkedinLin"
          target="_blank">
          <SvgoLinkedIn />
        </NuxtLink>
        <NuxtLink
          :aria-label="$t('aria.labels.share.twitter')"
          :href="shareableTwitterLink"
          target="_blank">
          <SvgoTwitterX />
        </NuxtLink>
      </div>
    </div>
  </section>
</template>

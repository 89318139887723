import {getQuery, withQuery} from "ufo";

export const useCanonicalUrl = (keepParams?: string[]) => {
  const reqUrl = useServerRequestUrl();
  const params = getQuery(reqUrl.value.href);

  const _params =
    keepParams && keepParams.length > 0
      ? Object.keys(params)
          .filter((key) => {
            return keepParams?.includes(key);
          })
          .reduce(
            (record, key) => {
              record[key] = params[key];
              return record;
            },
            {} as Record<string, string | string[]>,
          )
      : {};

  const normalized = reqUrl.value.originPath;
  return withQuery(normalized, _params);
};

type YouTubeThumbnailType =
  // 120x90
  | "1"
  | "2"
  | "3"
  | "default"
  // 320x180
  | "mq1"
  | "mq2"
  | "mq3"
  | "mqdefault"
  // 480x360
  | "0"
  | "hq1"
  | "hq2"
  | "hq3"
  | "hqdefault"
  // 640x480
  | "sd1"
  | "sd2"
  | "sd3"
  | "sddefault"
  // 1280x720
  | "hq720"
  // 1920x1080
  | "maxresdefault";

export function getYouTubeThumbnail(
  videoId: string,
  type: YouTubeThumbnailType,
  webp = true,
) {
  return `https://i.ytimg.com/${webp ? "vi_webp" : "vi"}/${videoId}/${type}.${webp ? "webp" : "jpg"}`;
}

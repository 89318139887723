<script lang="ts" setup>
import {EditablePage} from "@magnolia/vue-editor";
import {type Page, type SeoMeta} from "#magnolia-layer/types/magnolia-types";
import config from "~/templates/mapping";
import {joinURL} from "ufo";
import {FetchError} from "ofetch";
import {useCanonicalUrl} from "~/composables/useCanonicalUrl";
import ErrorDisplay from "~/components/ErrorDisplay.vue";
import {useLinkTransformer} from "~/composables/useLinkTransformer";
import {AccentColors} from "~/utils/colors";
import {
  getRandomColor,
  getRandomSceneType,
  type SceneType,
  useSceneState,
} from "~/composables/useSceneState";

type SceneLighting<T> = {
  field: T;
} & (T extends "custom"
  ? {
      customSceneLightingColor: string;
    }
  : {
      sceneLightingColor: keyof typeof AccentColors;
    });

type PageWithContent = Page & {
  title: string;
  robots?: string[];
  sceneLighting?: SceneLighting<"custom"> | SceneLighting<"predefined">;
  sceneType?: SceneType;
} & SeoMeta;

await useSiteGoogleTagManager();

const runtimeConfig = useRuntimeConfig();
const pagesApi = runtimeConfig.public.mgnl.apiPages;
const templateAnnotationsApi = runtimeConfig.public.mgnl.apiTemplates;

const magnoliaContext = useMagnoliaContext();

const {path} = useRoute();

const content = ref<PageWithContent>();
const {parseResponseStringToJson} = await useLinkTransformer();

const error = ref<FetchError | null>(null);
const pageResponse = await useMagnoliaFetch<PageWithContent>(
  joinURL(pagesApi, stripPagination(magnoliaContext.nodePath ?? "")),
  {
    parseResponse: parseResponseStringToJson,
  },
);

content.value = pageResponse.data.value ?? undefined;
error.value = pageResponse.error.value;

if (error.value) {
  const _error = error.value;
  if (
    process.env.NODE_ENV === "production" &&
    _error.cause instanceof TypeError
  ) {
    // TODO CHECK IF WE STILL NEED THIS
    // Original CORS request to forbidden server returns a 403 code,
    // on a route where it preferably shouldn't even try as it's a 404
    // but ofetch can't read the 403 and thus returns a TypeError instead of a FetchError with a 500
    throw createError({
      statusCode: 404,
      data: {
        originalError: _error,
      },
      fatal: true,
    });
  } else {
    if (_error.statusCode !== 404) {
      throw createError({
        ..._error,
        fatal: false,
        unhandled: false,
      });
    }
    const event = useRequestEvent();
    if (event) {
      setResponseStatus(event, _error.statusCode);
    }
  }
}

let templateAnnotations = ref<Record<string, string> | false>({});
if (import.meta.client && magnoliaContext.isMagnolia) {
  /**
   * Set to false to make sure the template annotations are loaded in Magnolia before trying to show the edit bars,
   * otherwise Magnolia JS will throw an error. We're not setting server only to {} because then
   * the expected outcomes for nuxt  between client and server differ. This way nuxt only warns in Magnolia edit mode.
   */
  templateAnnotations.value = false;
}

// Provide what Magnolia would provide for regular pages - just now for our virtual page
provide("config", config);
provide("templateAnnotations", templateAnnotations);
onMounted(async () => {
  if (magnoliaContext.isMagnolia) {
    // Won't seem to work with useMagnoliaFetch. Unsure why for now
    const baseURL = process.server
      ? runtimeConfig.mgnl.host
      : runtimeConfig.public.mgnl.host;
    const templateAnnotationsRes = await fetch(
      baseURL + templateAnnotationsApi + magnoliaContext.nodePath,
    );
    templateAnnotations.value = await templateAnnotationsRes.json();
    return;
  }
});

if (content.value) {
  const contentValue: PageWithContent = content.value;

  useI18nMultiTenantHead(
    {
      title: contentValue.title,
    },
    {
      languages: useMagnoliaLanguages(),
    },
  );
  const selectedImage = findAssetOnNodeProperties(contentValue, "ogImage");
  const fallbackImageRendition = findFirstImage(contentValue, "image");
  const image = selectedImage ?? fallbackImageRendition ?? null;

  const ogImage = image
    ? absoluteIfPossible(image, 1200 / 630, {width: 1200})
    : null;
  const ogUrl = useCanonicalUrl(["page"]);
  useSeoMeta({
    robots: contentValue.robots?.join(", "),
    title: contentValue.title,
    description: resolvePropertyStackString<SeoMeta>(contentValue, [
      "description",
    ]),
    ogUrl,
    ogTitle: resolvePropertyStackString(contentValue, ["ogTitle", "title"]),
    ogDescription: resolvePropertyStackString(contentValue, [
      "ogDescription",
      "description",
    ]),
    ogType: "website",
    ogImage: ogImage,
    twitterTitle: resolvePropertyStackString(contentValue, [
      "ogTitle",
      "title",
    ]),
    twitterDescription: resolvePropertyStackString(contentValue, [
      "ogDescription",
      "description",
    ]),
    twitterImage: ogImage,
    twitterCard: contentValue.twitterCard ?? "summary",
    articleModifiedTime: contentValue["mgnl:lastModified"],
  });
}
// /**
//  * Disable layout for slugs because we're handling them in the template below
//  * Using setPageLayout throws
//  * [warn] [nuxt] `setPageLayout` should not be called to change the layout on the server within a component as this will cause hydration errors.
//  */
// definePageMeta({
//   layout: false
// })

const pageClass = content.value?.["mgnl:template"]
  ? content.value["mgnl:template"].replace(/[^a-zA-Z0-9_-]/g, "-").toLowerCase()
  : "no-template";

const pageSceneColor = computed(() => {
  const sceneLighting = content.value?.sceneLighting;
  if (!sceneLighting) return undefined;
  if (sceneLighting.field === "custom") {
    return sceneLighting.customSceneLightingColor;
  }
  const accentColor = sceneLighting.sceneLightingColor;
  return AccentColors[accentColor]?.DEFAULT;
});

const {lightColor, type, randomPosition, tweenPositionDelta} = useSceneState();

watchEffect(() => {
  lightColor.value = error.value ? getRandomColor() : pageSceneColor.value;
  type.value = error.value ? getRandomSceneType() : content.value?.sceneType;
  randomPosition.value = error.value != undefined;
});
</script>

<template>
  <div class="grid min-h-dvh page-wrapper">
    <template v-if="error">
      <ErrorDisplay :error></ErrorDisplay>
    </template>
    <template v-else>
      <EditablePage
        :config="config"
        :content="content"
        :templateAnnotations="templateAnnotations" />
    </template>
  </div>
</template>

import {joinURL, withLeadingSlash, withProtocol} from "ufo";

function removePathPrefix(path: string, prefix: string) {
  // Ensure the path starts with a single slash
  path = withLeadingSlash(path);

  const localeRegex = /^[a-z]{2}(_[A-Z]{2})?$/; // Matches locales like nl or nl_NL

  // Split the path into parts
  const parts = path.split("/").filter(Boolean);

  // Check if the prefix is the first part or the second part (when preceded by a locale)
  if (parts[0] === prefix) {
    // Prefix is the first part
    parts.shift();
  } else if (parts[1] === prefix && localeRegex.test(parts[0])) {
    // Prefix is the second part and first part is a locale
    parts.splice(1, 1);
  }

  // Reconstruct the path
  return "/" + parts.join("/");
}

export const useLinkTransformer = async () => {
  const mgnl = useMagnoliaContext();
  const runtimeConfig = useRuntimeConfig();
  const languages = useMagnoliaLanguages();
  const defaultLanguage = languages[0];
  const publicHost = runtimeConfig.public.mgnl.host;

  // ToDo: Regarding language usage: downside here is that a specific language for a url may not exist.

  const resolveForAuthorEnvironment = (
    path: string,
    repoMapping?: string,
  ): string => {
    const _path =
      mgnl.currentLanguage && mgnl.currentLanguage !== defaultLanguage
        ? joinURL(mgnl.currentLanguage, path)
        : path;

    if (repoMapping) {
      return joinURL(publicHost, repoMapping, path);
    }
    // If the link goes the same website - relative.
    return removePathPrefix(_path, "trimm");
    // return removePathPrefix(_path, profile.pathPrefix);
  };

  const resolveForPublicEnvironment = (
    path: string,
    repoMapping?: string,
  ): string => {
    if (repoMapping) {
      return joinURL(publicHost, repoMapping, path);
    }
    const _path =
      mgnl.currentLanguage && mgnl.currentLanguage !== defaultLanguage
        ? joinURL(mgnl.currentLanguage, path)
        : path;
    // If the link goes the same website - relative.
    return removePathPrefix(_path, "trimm");
  };

  const resolveLink = (path: string, repository?: string): string => {
    const repoMapping = repository !== "website" ? repository : undefined;
    //
    // if (import.meta.dev) {
    //   return repoMapping ? joinURL(publicHost, repoMapping, path) : path;
    // }

    if (publicHost.includes("magnoliaAuthor")) {
      return resolveForAuthorEnvironment(path, repoMapping);
    }

    return resolveForPublicEnvironment(path, repoMapping);
  };

  const parseResponseString = (responseText: string) => {
    // Regular expression to find `${link:{...}}`
    const linkRegex =
      /\${link:\{uuid:\{([^}]+)\},repository:\{([^}]+)\},path:\{([^}]+)\}\}}/g;

    // Replace each placeholder with the resolved link
    return responseText.replace(linkRegex, (_, uuid, repository, path) => {
      try {
        return resolveLink(path, repository);
      } catch (error) {
        console.error(`Error resolving link: ${(error as Error).message}`);
        return "#"; // Fallback URL
      }
    });
  };

  return {
    parseResponseString,
    parseResponseStringToJson: (responseText: string) => {
      return JSON.parse(parseResponseString(responseText));
    },
  };
};

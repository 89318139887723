import {joinURL} from "ufo";
import type {Page} from "#magnolia-layer/types/magnolia-types";

export const useSiteGoogleTagManager = async () => {
  const magnoliaContext = useMagnoliaContext();
  const runtimeConfig = useRuntimeConfig();
  const siteRootNode = magnoliaContext.nodePath?.split("/").filter((v) => v)[0];
  const url = joinURL(runtimeConfig.public.mgnl.apiPages, siteRootNode ?? "");

  const {data} = await useMagnoliaFetch<Page & {gtmId?: string}>(url);
  const gtmId = data.value?.gtmId;
  if (!gtmId) {
    return;
  }

  useScriptGoogleTagManager({
    id: gtmId,
  });
};

<script setup lang="ts">
import {z} from "zod";
import type {FormSubmitEvent} from "#ui/types";
import {SvgoRightArrow, UCheckbox} from "#components";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import type {Component} from "vue";
const {t, locale: currentLocale} = useI18n();
const {dataLayer} = useScriptGoogleTagManager();

const props = defineProps<{
  metadata: Component;
  portalId: string;
  formId: string;
  languageValue?: string;
}>();

const schema = z.object({
  firstName: z
    .string({message: t("contactForm.validation.required")})
    .min(1, t("contactForm.validation.required")),
  lastName: z
    .string({message: t("contactForm.validation.required")})
    .min(1, t("contactForm.validation.required")),
  telephone: z.string({message: t("contactForm.validation.required")}).regex(
    /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, //https://regex101.com/r/j48BZs/2
    t("contactForm.validation.invalidPhone"),
  ),
  email: z
    .string({message: t("contactForm.validation.required")})
    .email({message: t("contactForm.validation.invalidEmail")}),
  message: z
    .string({message: t("contactForm.validation.required")})
    .min(1, t("contactForm.validation.required")),
  termsAndPolicy: z.literal(true, {
    message: t("contactForm.validation.required"),
    errorMap: (err, ctx) => {
      return {
        message:
          err.code === "invalid_literal"
            ? t("contactForm.validation.required")
            : ctx.defaultError,
      };
    },
  }),
  language: z.string().min(1),
  newsletter: z.boolean(),
  "g-recaptcha-response": z.string().optional(),
});

let state = reactive({
  firstName: undefined,
  lastName: undefined,
  telephone: undefined,
  email: undefined,
  message: undefined,
  termsAndPolicy: undefined,
  language: props.languageValue
    ? props.languageValue
    : currentLocale.value.toUpperCase(),
  newsletter: false,
  "g-recaptcha-response": undefined,
});

let success: Ref<boolean | undefined> = ref(undefined);
const top = useTemplateRef("top");

if (import.meta.client) {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
}

type Schema = z.output<typeof schema>;

async function onSubmit(event: FormSubmitEvent<Schema>) {
  success.value = undefined;

  executeRecaptcha("submit").then(async (token) => {
    if (token) {
      event.data["g-recaptcha-response"] = token;
    }

    try {
      const response = await $fetch("/api/form/contact", {
        query: {
          portalId: props.portalId,
          formId: props.formId,
        },
        method: "POST",
        body: event.data,
      });

      if (response) {
        success.value = true;
        dataLayer.push({
          event: "form_submit",
          formName: "contact",
          submission: "success",
        });
      }
    } catch (e) {
      success.value = false;
    } finally {
      Object.assign(state, {
        firstName: undefined,
        lastName: undefined,
        telephone: undefined,
        email: undefined,
        message: undefined,
        language: undefined,
        newsletter: undefined,
        termsAndPolicy: undefined,
        "g-recaptcha-response": undefined,
      });

      if (top.value) {
        const y = top.value.offsetTop;

        gsap.to(window, {
          duration: 1,
          scrollTo: {
            y,
          },
          ease: "Power1.easeInOut",
        });
      }
    }
  });
}

const {executeRecaptcha} = useRecaptcha();
</script>

<template>
  <div class="inner-content-grid" ref="top">
    <UAlert
      v-if="success"
      icon="i-heroicons-check-circle-solid"
      color="patina"
      variant="solid"
      :title="$t('contactForm.submitted.title')"
      :description="$t('contactForm.submitted.description')" />

    <UAlert
      v-else-if="success === false"
      icon="i-heroicons-x-circle-solid"
      color="scarlet"
      variant="solid"
      :title="$t('contactForm.failed.title')"
      :description="$t('contactForm.failed.description')" />
  </div>
  <UForm
    v-if="success === undefined"
    :schema="schema"
    :state="state"
    @submit="onSubmit"
    class="inner-content-grid my-12">
    <UInput class="hidden" v-model="state.language"></UInput>

    <UFormGroup
      :label="$t('contactForm.fields.firstName.label')"
      name="firstName"
      class="col-span-4 lg:col-span-6">
      <UInput
        :placeholder="$t('contactForm.fields.firstName.placeholder')"
        v-model="state.firstName"></UInput>
    </UFormGroup>

    <UFormGroup
      :label="$t('contactForm.fields.lastName.label')"
      name="lastName"
      class="col-span-4 lg:col-span-6">
      <UInput
        :placeholder="$t('contactForm.fields.lastName.placeholder')"
        v-model="state.lastName"></UInput>
    </UFormGroup>

    <UFormGroup
      :label="$t('contactForm.fields.telephone.label')"
      name="telephone"
      class="col-span-4 lg:col-span-6">
      <UInput
        :placeholder="$t('contactForm.fields.telephone.placeholder')"
        v-model="state.telephone"></UInput>
    </UFormGroup>

    <UFormGroup
      :label="$t('contactForm.fields.email.label')"
      name="email"
      class="col-span-4 lg:col-span-6">
      <UInput
        :placeholder="$t('contactForm.fields.email.placeholder')"
        v-model="state.email"></UInput>
    </UFormGroup>

    <UFormGroup
      :label="$t('contactForm.fields.message.label')"
      name="message"
      class="col-span-4 lg:col-span-12">
      <UTextarea
        :rows="10"
        :placeholder="$t('contactForm.fields.message.placeholder')"
        v-model="state.message"></UTextarea>
    </UFormGroup>

    <div class="col-span-4 lg:col-span-10 flex flex-col gap-4">
      <UFormGroup name="newsletter">
        <UCheckbox
          class="w-fit"
          name="newsletter"
          :label="$t('contactForm.fields.newsletter.label')"
          v-model="state.newsletter" />
      </UFormGroup>

      <UFormGroup name="termsAndPolicy">
        <UCheckbox
          class="w-fit"
          name="termsAndPolicy"
          :label="$t('contactForm.fields.termsAndPolicy.label')"
          v-model="state.termsAndPolicy" />
      </UFormGroup>
    </div>
    <div class="col-span-4 lg:col-span-2 self-end place-self-end">
      <button class="trimm-button" type="submit" value="Submit">
        <span class="pr-4">
          {{ $t("contactForm.button.send.label") }}
        </span>
        <SvgoRightArrow />
      </button>
    </div>
  </UForm>
</template>

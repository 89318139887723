import type {Component} from "vue";
import type {Config} from "@magnolia/vue-editor";
import Default from "~/templates/pages/Default.vue";
import Image from "~/templates/components/Image.vue";
import Text from "~/templates/components/Text.vue";
import TextHeader from "~/templates/components/TextHeader.vue";
import PageOverview from "~/templates/components/PageOverview.vue";
import Marquee from "~/templates/components/CustomMarquee.vue";
import QuoteSlider from "~/templates/components/QuoteSlider.vue";
import PageFooter from "~/templates/components/PageFooter.vue";
import SignUpForm from "~/components/SignUpForm.vue";
import SpecialTitle from "~/templates/components/SpecialTitle.vue";
import LogosMarquee from "~/templates/components/LogosMarquee.vue";
import Video from "~/templates/components/Video.vue";
import CTA from "~/templates/components/CTA.vue";
import ImageHeader from "~/templates/components/ImageHeader.vue";
import Columns from "~/templates/components/Columns.vue";
import Column from "~/templates/components/Column.vue";
import Accordion from "~/templates/components/Accordion.vue";
import AccordionItem from "~/templates/components/AccordionItem.vue";
import HighlightedPages from "~/templates/components/HighlightedPages.vue";
import TwoColumns from "~/templates/components/TwoColumns.vue";
import TextSection from "~/templates/components/TextSection.vue";
import TextImage from "~/templates/components/TextImage.vue";
import Share from "~/templates/components/Share.vue";
import ContactForm from "~/templates/components/ContactForm.vue";
import Cards from "~/templates/components/Cards.vue";
import Card from "~/templates/components/Card.vue";
import Map from "~/templates/components/Map.vue";
import TrButton from "~/templates/components/TrButton.vue";
import LottieAnimation from "~/templates/components/LottieAnimation.vue";
import DynamicForm from "~/templates/components/DynamicForm.vue";

const pages: Record<string, Component> = {
  "module-trimm-website:pages/home": Default,
  "module-trimm-website:pages/default": Default,
  "module-trimm-website:pages/reading": Default,
};
const components: Record<string, Component> = {
  "module-trimm-website:components/text": Text,
  "module-trimm-website:components/textBlock": TextSection,
  "module-trimm-website:components/textHeader": TextHeader,
  "module-trimm-website:components/imageHeader": ImageHeader,
  "module-trimm-website:components/pageOverview": PageOverview,
  "module-trimm-website:components/image": Image,
  "module-trimm-website:components/marquee": Marquee,
  "module-trimm-website:components/quoteSlider": QuoteSlider,
  "module-trimm-website:components/pageFooter": PageFooter,
  "module-trimm-website:components/signUpForm": SignUpForm,
  "module-trimm-website:components/specialTitle": SpecialTitle,
  "module-trimm-website:components/logosMarquee": LogosMarquee,
  "module-trimm-website:components/video": Video,
  "module-trimm-website:components/cta": CTA,
  "module-trimm-website:components/columns": Columns,
  "module-trimm-website:components/column": Column,
  "module-trimm-website:components/accordion": Accordion,
  "module-trimm-website:components/accordionItem": AccordionItem,
  "module-trimm-website:components/highlightedPages": HighlightedPages,
  "module-trimm-website:components/twoColumns": TwoColumns,
  "module-trimm-website:components/textImage": TextImage,
  "module-trimm-website:components/share": Share,
  "module-trimm-website:components/contactForm": ContactForm,
  "module-trimm-website:components/cards": Cards,
  "module-trimm-website:components/card": Card,
  "module-trimm-website:components/map": Map,
  "module-trimm-website:components/button": TrButton,
  "module-trimm-website:components/lottieAnimation": LottieAnimation,
  "module-trimm-website:components/dynamicForm": DynamicForm,
};
const config: Config = {
  // TODO: Check if auto generation possible
  componentMappings: {
    ...pages,
    ...components,
  },
};

export default config;

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
<script lang="ts" setup>
import TextBlock from "~/components/TextBlock.vue";
import {EditableArea} from "@magnolia/vue-editor";
import type {Area} from "#magnolia-layer/types/magnolia-types";

const props = defineProps<{
  eyebrow?: string;
  title?: string;
  body?: string;
  actions?: Area;
}>();
</script>

<template>
  <section class="inner-content-grid my-12 min-h-[65vh] items-center">
    <div
      :style="{'--trimm-button-hover-color': 'var(--color-teal)'}"
      class="col-span-4 p-6 pl-10 lg:p-12 lg:py-16 lg:pl-20 trimm-cutout-tl-6 lg:trimm-cutout-tl-12 bg-teal bg-opacity-60 contrast-custom:bg-opacity-100 backdrop-blur-2xl lg:col-start-3 lg:col-span-8">
      <TextBlock :eyebrow="eyebrow" :title="title" :body="body" uppercase>
        <template #actions>
          <EditableArea :content="actions" class="flex flex-wrap gap-4" />
        </template>
      </TextBlock>
    </div>
  </section>
</template>

<style lang="scss" scoped>
:deep(p) {
  @apply lg:mr-28;
}
</style>

<script setup lang="ts">
import type {Page} from "#magnolia-layer/types/magnolia-types";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitText from "gsap/SplitText";

const props = withDefaults(
  defineProps<{
    page: Page;
    buttonLabel?: string;
    animate?: boolean;
  }>(),
  {
    animate: true,
  },
);

if (import.meta.client) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(SplitText);
}
const wrapperRef = ref();
onMounted(() => {
  if (!wrapperRef.value || !props.animate) return;
  const target = wrapperRef.value;
  gsap.from(target, {
    y: 64,
    alpha: 0,
    duration: 0.5,
    scrollTrigger: {
      trigger: target,
      toggleActions: "play none none reverse",
      // scrub: true,
      end: "top bottom-=128px",
    },
  });
});
</script>

<template>
  <article ref="wrapperRef">
    <MagnoliaPicture
      :asset="page.image"
      class="block mb-8"
      :modifiers="{
        extract: imageToExtractionArea(page.image, 37 / 46),
      }" />
    <TextBlock
      :title="page.title"
      :body="page.description"
      :eyebrow="getFirstTag(page)"
      :indent="false" />
    <NuxtLinkLocale
      :href="getPageLink(page['@path'])"
      target="_self"
      class="trimm-button mt-6">
      <span class="pr-4" v-if="buttonLabel">
        {{ buttonLabel }}
      </span>
      <span class="pr-4" v-else>
        {{ $t("pageOverview.item.button.label") }}
      </span>
      <SvgoRightArrow />
    </NuxtLinkLocale>
  </article>
</template>

<style scoped lang="scss"></style>

type VideoIdMatcher<T extends string | number> = {
  idPosition: number;
  matcher: RegExp;
  platform: T extends string ? "youtube" : "vimeo";
};

const videoMatchers: VideoIdMatcher<any>[] = [
  {
    platform: "youtube",
    matcher:
      /(youtube\.com|youtu\.be|youtube-nocookie\.com)\/(watch\?(.*&)?v=|v\/|u\/|embed\/?)?(videoseries\?list=(.*)|[\w-]{11}|\?listType=(.*)&list=(.*))(.*)/i,
    idPosition: 4,
  },
  {
    platform: "vimeo",
    matcher: /vimeo\.com\/(.*\/)?([\d]+)(.*)?/i,
    idPosition: 2,
  },
];

const getIdFromMatcher = (
  url: string,
  matcher: VideoIdMatcher<any>,
): string | undefined => {
  const match = url.match(matcher.matcher);
  return match ? match[matcher.idPosition] : undefined;
};
const isMatch = (url: string, matcher: RegExp): boolean => {
  const m = url.match(matcher);
  return !!m && m.length > 1;
};

// Custom type guard to determine if the platform is YouTube
const isYouTube = (
  matcher: VideoIdMatcher<any>,
): matcher is VideoIdMatcher<string> => {
  return matcher.platform === "youtube";
};

// Custom type guard to determine if the platform is Vimeo
const isVimeo = (
  matcher: VideoIdMatcher<any>,
): matcher is VideoIdMatcher<number> => {
  return matcher.platform === "vimeo";
};

export const parseUrl = (url: string) => {
  const match = videoMatchers.find((entry) => isMatch(url, entry.matcher));
  if (!match) return undefined;

  const id = getIdFromMatcher(url, match);
  if (isYouTube(match)) {
    return {platform: "youtube", id: id!} as const; // YouTube IDs are strings
  } else if (isVimeo(match)) {
    return {platform: "vimeo", id: id ? parseInt(id, 10) : 0} as const; // Vimeo IDs are numbers
  }
  return undefined;
};

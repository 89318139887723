<script lang="ts" setup>
import type {
  Component,
  FocalPointAsset,
  FromResolver,
} from "#magnolia-layer/types/magnolia-types";
import type {AspectRatio} from "#base-layer/utils/parseAspectRatio";
import {didResolve} from "#imports";

const {
  metadata,
  image,
  aspectRatio = "original",
  cutout = "none",
  align = "left",
} = defineProps<{
  metadata?: Component;
  image: FromResolver<FocalPointAsset>;
  aspectRatio?: "original" | AspectRatio;
  cutout?: "none" | "tl" | "tr" | "br" | "bl";
  align?: "left" | "right";
}>();

const assumeAboveFold = (metadata?.["@index"] ?? 0) < 1;
const loading = assumeAboveFold ? "eager" : "lazy";

const parsedRatio =
  aspectRatio === "original" ? undefined : parseAspectRatio(aspectRatio);
const extract =
  parsedRatio === undefined
    ? undefined
    : imageToExtractionArea(image, parsedRatio);

const resolvedImage = computed(() => {
  if (didResolve(image)) return image;
});
</script>

<template>
  <section class="col-fullwidth inner-content-grid image my-12">
    <MagnoliaPicture
      v-if="resolvedImage"
      :asset="resolvedImage"
      :loading="loading"
      :modifiers="{
        extract,
      }"
      format="avif,webp"
      :quality="100"
      :auto-size="`width-${resolvedImage.metadata.width}`"
      :class="[
        cutout !== 'none' ? `trimm-cutout-${cutout}-12` : '',
        align === 'right' ? `lg:justify-self-end` : 'lg:justify-self-start',
      ]"
      densities="1x 2x"></MagnoliaPicture>
  </section>
</template>

<style lang="scss" scoped></style>

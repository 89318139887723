<script lang="ts" setup>
const {accentColor} = defineProps<{
  title?: string;
  accentColor: keyof typeof AccentColors;
}>();

const uuid = inject<string | undefined>("uuid", undefined);
const afterCls =
  "after:bg-gradient-to-br after:from-[--accent-color] after:opacity-0 open:after:opacity-100 after:transition-opacity after:inset-0 after:absolute after:-z-10 contrast-custom:after:from-black contrast-custom:after:to-black";
</script>
<template>
  <details
    ref="details"
    :style="{
      '--accent-color': `var(--color-${accentColor})`,
    }"
    :name="uuid"
    :class="afterCls"
    class="item isolate border-t border-b border-[--accent-color] -mb-[1px] overflow-hidden relative [&_.minus]:open:block [&_.minus]:hidden [&_.plus]:open:hidden [&_.plus]:block">
    <summary
      class="cursor-pointer appearance-none list-none pb-6 pt-6 pl-4 pr-4">
      <h1 class="text-2xl flex justify-between align-center lg:text-3xl">
        {{ title }}
        <span>
          <SvgoMinusIcon class="*:stroke-current minus" />
          <SvgoPlusIcon class="plus" />
        </span>
      </h1>
    </summary>
    <div class="content overflow-hidden pl-4 pr-4 pb-6">
      <slot></slot>
    </div>
  </details>
</template>

<style lang="scss" scoped>
details summary::-webkit-details-marker {
  display: none;
}
</style>
